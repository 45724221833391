var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assessment-all"},[_c('app-title-content',{staticClass:"mb-4",attrs:{"root":_vm.pageBreadcrumb,"name":"AssessmentList","rootBc":_vm.rootBc}}),_c('div',{staticClass:"d-flex justify-content-end mb-4"},[_c('button',{staticClass:"btn btn-outline-submit",attrs:{"type":"submit"},on:{"click":_vm.gotoList}},[_vm._v(" กลับไปยังหน้ารายการ ")])]),_c('div',{staticClass:"box-shadow p-5"},[_c('div',{staticClass:"align-items-baseline d-flex flex-fill mb-3"},[_c('div',{staticClass:"font-headblue fontsize-h4"},[_c('iconify',{attrs:{"icon":"majesticons:clipboard-list"}}),_c('span',{staticClass:"weight-700 ml-1"},[_vm._v("รายการประเมินคุณภาพระบบประปาหมู่บ้านล่าสุด")])],1),_c('span',{staticClass:"ml-2"},[_vm._v("จำนวน "+_vm._s(_vm._f("toCommas")(_vm.total))+" รายการ")])]),_c('b-table',{staticClass:"table-custom mb-4 box-shadow",attrs:{"id":"my-table-listall","hover":"","borderless":"","show-empty":"","items":_vm.items,"fields":_vm.fields,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center fontsize-h4 my-4"},[_vm._v(" ท่านยังไม่เคยทำแบบประเมินคุณภาพระบบประปา ")])]},proxy:true},{key:"head()",fn:function(data){return [_c('div',{class:data.field.align === 'left'
              ? 'text-left'
              : data.field.align === 'right'
              ? 'text-right'
              : 'text-center'},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"head(index)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"cell(index)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.perPage + (data.index + 1))+" ")])]}},{key:"cell()",fn:function(data){return [(data.item.alignRow !== undefined)?_c('div',{class:data.item.alignRow === 'left'
              ? 'text-left'
              : data.item.alignRow === 'right'
              ? 'text-right'
              : 'text-center'},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',{class:data.field.align === 'left'
              ? 'text-left'
              : data.field.align === 'right'
              ? 'text-right'
              : 'text-center'},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(result)",fn:function(row){return [_c('div',[_c('iconify',{class:_vm.getColorIcon(row.item.assessmentResult.statusTextSymbol),attrs:{"icon":_vm.getIcon(row.item.assessmentResult.statusTextSymbol)}}),_vm._v(" "+_vm._s(row.item.assessmentResult.statusText)+" ")],1)]}},{key:"cell(viewResult)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('font-awesome-icon',{staticClass:"fontsize-h2 font-systemblue pr-2",attrs:{"icon":"file-alt"}}),_c('a',{staticClass:"font-systemblue cursor-pointer",on:{"click":function($event){return _vm.onViewResult(data.item)}}},[_vm._v("เรียกดู")])],1)]}},{key:"cell(canEdit)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-edit",attrs:{"type":"button","disabled":!data.item.canEdit},on:{"click":function($event){return _vm.editRow(data.item)}}},[_c('font-awesome-icon',{staticClass:"color-icon-edit fa-lg",attrs:{"icon":['fas', 'edit']}})],1)])]}},{key:"cell(canDelete)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-trash",attrs:{"type":"button","disabled":!data.item.canDelete},on:{"click":function($event){return _vm.deleteRow(data.item)}}},[_c('font-awesome-icon',{staticClass:"color-icon-trash fa-lg",attrs:{"icon":['fas', 'trash-alt']}})],1)])]}}])},[_c('div',{staticClass:"text-center font-blueinfo my-2",attrs:{"slot":"table-busy"},slot:"table-busy"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"pl-2"},[_vm._v("กำลังโหลด...")])],1)]),_c('b-pagination',{staticClass:"pagination-custom mt-3",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"align":"right","aria-controls":"my-table-listall"},on:{"change":function($event){return _vm.changePage($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }