<template>
  <div class="assessment-all">
    <app-title-content
      class="mb-4"
      :root="pageBreadcrumb"
      name="AssessmentList"
      :rootBc="rootBc"
    ></app-title-content>

    <div class="d-flex justify-content-end mb-4">
      <button type="submit" class="btn btn-outline-submit" @click="gotoList">
        กลับไปยังหน้ารายการ
      </button>
    </div>

    <div class="box-shadow p-5">
      <div class="align-items-baseline d-flex flex-fill mb-3">
        <div class="font-headblue fontsize-h4">
          <iconify icon="majesticons:clipboard-list"></iconify>
          <span class="weight-700 ml-1"
            >รายการประเมินคุณภาพระบบประปาหมู่บ้านล่าสุด</span
          >
        </div>
        <span class="ml-2">จำนวน {{ total | toCommas }} รายการ</span>
      </div>
      <b-table
        id="my-table-listall"
        class="table-custom mb-4 box-shadow"
        hover
        borderless
        show-empty
        :items="items"
        :fields="fields"
        :busy.sync="isBusy"
      >
        <template v-slot:empty="">
          <div class="text-center fontsize-h4 my-4">
            ท่านยังไม่เคยทำแบบประเมินคุณภาพระบบประปา
          </div>
        </template>

        <div slot="table-busy" class="text-center font-blueinfo my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="pl-2">กำลังโหลด...</strong>
        </div>

        <template v-slot:head()="data">
          <div
            :class="
              data.field.align === 'left'
                ? 'text-left'
                : data.field.align === 'right'
                ? 'text-right'
                : 'text-center'
            "
          >
            {{ data.label }}
          </div>
        </template>

        <template v-slot:head(index)="data">
          <div class="text-center">
            {{ data.label }}
          </div>
        </template>

        <!-- Row running number -->
        <template v-slot:cell(index)="data">
          <div class="text-center">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </div>
        </template>

        <!-- Row Custom align -->
        <template v-slot:cell()="data">
          <div
            v-if="data.item.alignRow !== undefined"
            :class="
              data.item.alignRow === 'left'
                ? 'text-left'
                : data.item.alignRow === 'right'
                ? 'text-right'
                : 'text-center'
            "
          >
            {{ data.value }}
          </div>
          <div
            v-else
            :class="
              data.field.align === 'left'
                ? 'text-left'
                : data.field.align === 'right'
                ? 'text-right'
                : 'text-center'
            "
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(result)="row">
          <div>
            <iconify
              :icon="getIcon(row.item.assessmentResult.statusTextSymbol)"
              :class="getColorIcon(row.item.assessmentResult.statusTextSymbol)"
            />
            {{ row.item.assessmentResult.statusText }}
          </div>
        </template>

        <template v-slot:cell(viewResult)="data">
          <div class="d-flex">
            <font-awesome-icon
              icon="file-alt"
              class="fontsize-h2 font-systemblue pr-2"
            />
            <a
              class="font-systemblue cursor-pointer"
              @click="onViewResult(data.item)"
              >เรียกดู</a
            >
          </div>
        </template>

        <template v-slot:cell(canEdit)="data">
          <div class="text-center">
            <button
              type="button"
              class="btn btn-edit"
              :disabled="!data.item.canEdit"
              @click="editRow(data.item)"
            >
              <font-awesome-icon
                :icon="['fas', 'edit']"
                class="color-icon-edit fa-lg"
              />
            </button>
          </div>
        </template>

        <template v-slot:cell(canDelete)="data">
          <div class="text-center">
            <button
              type="button"
              class="btn btn-trash"
              :disabled="!data.item.canDelete"
              @click="deleteRow(data.item)"
            >
              <font-awesome-icon
                :icon="['fas', 'trash-alt']"
                class="color-icon-trash fa-lg"
              />
            </button>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="pagination-custom mt-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="right"
        aria-controls="my-table-listall"
        @change="changePage($event)"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { AssessmentService } from "@/services/main.service.js";
import { UserCardIdService } from "@/services/storage.service";

export default {
  name: "assessment-all",
  data() {
    return {
      pageBreadcrumb: {
        icon: null,
        text: "หน้าหลักประเมินคุณภาพระบบประปาหมู่บ้าน",
        to: "AssessmentList",
      },
      rootBc: {
        to: "AssessmentList",
      },
      isBusy: false,
      perPage: 50,
      currentPage: 1,
      items: [],
      fields: [
        { key: "index", label: "ลำดับ" },
        { key: "villageName", label: "หมู่บ้าน/ชุมชน", align: "left" },
        { key: "villageNameNumber", label: "หมู่", align: "left" },
        { key: "tambolName", label: "ตำบล", align: "left" },
        { key: "amphorName", label: "อำเภอ", align: "left" },
        { key: "provinceName", label: "จังหวัด", align: "left" },
        { key: "systemPlumbingName", label: "ชื่อระบบประปา", align: "left" },
        { key: "pwaType", label: "ประเภทแหล่งน้ำ", align: "left" },
        { key: "result", label: "ผลการประเมิน", align: "left" },
        { key: "viewResult", label: "ดูสรุปผล", align: "left" },
        { key: "modifyDate", label: "วันที่อัพเดทข้อมูล", align: "left" },
        { key: "canEdit", label: "แก้ไข" },
        { key: "canDelete", label: "ลบ" },
      ],
      total: 0,
      personId: null,
    };
  },
  async created() {
    this.personId = UserCardIdService.getUserCardId();

    await this.getListQualitySurvey();
  },
  methods: {
    async changePage(event) {
      this.currentPage = event;

      //call service ...
      await this.getListQualitySurvey();
    },
    async getListQualitySurvey() {
      this.isBusy = true;
      const param = {
        personId: this.personId,
        NumPage: this.currentPage,
        NumRow: this.perPage,
      };
      await AssessmentService.getListQualitySurvey(param)
        .then((res) => {
          this.items = res.data.map((m) => {
            return {
              ...m,
              canEdit: true,
              canDelete: true,
            };
          });

          this.total = res.total;
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
          this.currentPage = 1;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    goBack() {
      //goBack
      this.$router.push({ name: "AssessmentList" });
    },
    gotoList() {
      this.$router.push({ name: "AssessmentList" });
    },
    onViewResult(detail) {
      const param = {
        surveyID: detail.qualitySurveyID,
        type: detail.pwaType,
      };
      this.$router.push({
        name: "AssessmentSummary",
        query: {
          ...param,
        },
      });
    },
    editRow(detail) {
      const param = {
        surveyID: detail.qualitySurveyID,
        type: detail.pwaType,
      };
      if (detail.pwaTypeId === 1) {
        this.$router.push({
          name: "SurfaceForm",
          params: { mode: "edit" },
          query: {
            ...param,
          },
        });
      } else if (detail.pwaTypeId === 2) {
        this.$router.push({
          name: "GroundForm",
          params: { mode: "edit" },
          query: {
            ...param,
          },
        });
      }
    },
    deleteRow(detail) {
      const isLastRowInLastPage = this.checkLastPage();
      this.alertDeleteForm().then(async (result) => {
        if (result) {
          //call service delete
          await AssessmentService.postAssessmentFormDelete(
            detail.qualitySurveyID
          )
            .then((res) => {
              if (res.actionId !== 0) {
                this.alertSuccess("ลบรายการสำเร็จ");

                if (isLastRowInLastPage) this.currentPage--;
                //refresh list
                this.getListQualitySurvey();
              }
            })
            .catch((err) => {
              this.alertFail("ลบรายการไม่สำเร็จ");
            });
        }
      });
    },
    checkLastPage() {
      const total = this.total;
      const perPage = this.perPage;
      const currentPage = this.currentPage;
      const isLastRowInLastPage = total === perPage * (currentPage - 1) + 1;
      return isLastRowInLastPage;
    },
    getIcon(item) {
      if (item === "A") return "bi:emoji-heart-eyes-fill";
      else if (item === "B") return "bi:emoji-smile-fill";
      else if (item === "C") return "bi:emoji-wink-fill";
      else if (item === "D") return "bi:emoji-neutral-fill";
      else if (item === "F") return "bi:emoji-frown-fill";
    },
    getColorIcon(item) {
      if (item === "A") return "font-graph-green";
      else if (item === "B") return "font-graph-softgreen";
      else if (item === "C") return "font-graph-yellow";
      else if (item === "D") return "font-graph-orange";
      else if (item === "F") return "font-graph-rad";
    },
  },
};
</script>
